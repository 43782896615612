import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import Highlight from 'components/content-blocks/highlight';
import Wysiwyg from 'components/content-blocks/partials/wysiwyg';
import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';
import PartnersFormModal from 'components/modal/partners';

import './styles/partners.scss';

const Partners = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "partners" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                copy
                background_desktop_image {
                  source_url
                }
                partners_form {
                  button_text
                }
              }
              cards_1 {
                card {
                  title
                  copy
                  image {
                    source_url
                  }
                  link_url
                  link_text
                }
              }
              basic_title_subtitle_copy_image {
                copy
                title
                button_url
                button_text
                desktop_image {
                  source_url
                }
              }
              basic_title_subtitle_copy_image_2 {
                wysiwyg
              }
            }
          }
        }
      }
    }
  `);
  
  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const hero = pageContext.hero;
  const cards1 = pageContext.cards_1;
  const contactForm = pageContext.basic_title_subtitle_copy_image;
  const wysiwyg = pageContext.basic_title_subtitle_copy_image_2.wysiwyg;
  
  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="partners-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup { ...hero } button />
        <Highlight { ...cards1 } hasImage hasTitle hasCopy />
        <section className="image-group-section section">
          <div className="container">
            <Wysiwyg content={ wysiwyg } listItems />
          </div>
        </section>
        <section className="partners-contact-form-section section">
          <div className="container">
            <h2 className="title has-text-center">{contactForm.title}</h2>
            <p className="copy">{contactForm.copy}</p>
            <PartnersFormModal { ...contactForm } className="button is-green is-rounded" />
          </div>
        </section>
      </div>

    </Layout>
  );
};

export default Partners;